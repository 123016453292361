@import '../../theme.sass'

.loader
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100vh

  img
    height: 240px
    width: 240px

  h5
    margin-top: 2rem
    font-family: InterBold, sans-serif !important


.ldsRing
  margin-top: 1rem
  display: inline-block
  width: 80px
  height: 80px
  //top: calc(50% - 40px)

  span
    box-sizing: border-box
    display: block
    position: absolute
    width: 48px
    height: 48px
    margin: 16px
    border: 6px solid
    border-radius: 50%
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: $brand-color transparent transparent transparent

    &:nth-child(1)
      animation-delay: -0.45s

    &:nth-child(2)
      animation-delay: -0.3s

    &:nth-child(3)
      animation-delay: -0.15s

@keyframes ldsRing
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
