@import '../../theme'

.menu
  display: flex
  align-items: center
  justify-content: space-between
  height: 5rem
  background-color: transparent
  width: 100%
  transition: background-color .2s ease-in-out

  .bg
    background-color: $white-color
