@import '../../theme'

.checkbox
  display: flex
  align-items: center
  gap: 1rem

  input
    display: none

  label
    padding-left: 3rem
    font-size: .9rem
    cursor: pointer

    &:after
      content: ""
      width: 2.4rem
      height: 1.4rem
      background-color: $brand-color
      position: absolute
      top: -.2rem
      left: 0
      border-radius: 1rem
      transition: background-color .2s ease-in-out

    &:before
      content: ""
      width: 1rem
      height: 1rem
      border-radius: 1rem
      position: absolute
      top: 0
      left: 1.2rem
      background: $main-color
      z-index: 2
      transition: all .2s ease-in-out

    &.checkbox__label__uncheked
      &:before
        background: $white-color
        left: .2rem
      &:after
        background: $span-color



.checkbox__label
  a
    color: $brand-color
    transition: color .2s ease-in-out

  &.desibled
    opacity: .5
    cursor: default

@media (hover: hover)
  .checkbox__label
    a:hover
      color: $white-color
