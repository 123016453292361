@import '../../theme'

.partnersSlider
  background: $main-color
  padding: 8rem 0 5rem

  .partnetSlidet__slider
    margin-top: 4rem
    img
      background: $card-fill-color
      padding: 1rem
      border-radius: 1rem

  h2, h4
    color: $white-color

  h4
    margin-bottom: 1.2rem

  p
    color: $lite-span-color
    line-height: 1.2rem

.partnetSlidet__title
  display: flex
  justify-content: space-between
  align-items: flex-start

  .partnetSlidet__subtitle
    max-width: 20rem

.partnetSlidet__counter
  display: flex
  flex-direction: column

  .partnetSlidet__counterItem
    display: flex
    align-items: flex-start
    justify-content: space-between
    border-top: $card-border-style_dark
    &:first-child
      border: none

    p
      padding: 4rem 0
      line-height: 1


    p:first-child
      font-size: 3.4rem
      font-family: InterBold, sans-serif
      color: $white-color


@media screen and (max-width: 768px)
  .partnersSlider
    padding-top: 4rem
    padding-bottom: 0
    h2
      margin-bottom: 2rem

    .partnetSlidet__slider
      img
        border-radius: 0

  .partnetSlidet__counter
    .partnetSlidet__counterItem
      flex-direction: column
      p
        &:last-child
          padding-top: 0

  .partnetSlidet__title
    flex-wrap: wrap
