.partnerBannerWrapper
  padding-top: 6rem
  overflow: hidden
  &:before
    content: ""
    position: absolute
    background: url('../../../../public/icon/before.svg') no-repeat
    background-position-x: right
    top: -10rem
    right: -30rem
    width: 100rem
    height: 100rem

.partnerBanner
  display: flex

  .left
    max-width: 35%

    h1
      margin-bottom: 1rem
      margin-top: 4rem

    p
      margin-bottom: 4rem
      line-height: 1.2rem

  .right
    width: 100%
    right: -4rem

    img
      right: 0
      width: 100rem
      height: auto
      object-fit: contain
      max-width: 150%

@media screen and (min-width: 1830px)
  .right
    margin-top: -2rem
    margin-bottom: 2rem

  .partnerBannerWrapper
    &:before
      top: -15rem
      width: 65rem
      height: 65rem
      background-size: cover

@media screen and (min-width: 1920px)
  .right
    margin-top: -2rem
    margin-bottom: 2rem

  .partnerBannerWrapper
    &:before
      width: 70rem
      height: 70rem
      background-size: cover

@media screen and (min-width: 2470px)
  .partnerBannerWrapper
    &:before
      top: -15rem
      width: 75rem
      height: 75rem
      background-size: cover

@media screen and (min-width: 3294px)
  .partnerBannerWrapper
    &:before
      top: -20rem
      width: 100rem
      height: 100rem
      background-size: cover

@media screen and (min-width: 4941px)
  .partnerBannerWrapper
    &:before
      top: -50rem
      width: 145rem
      height: 145rem
      background-size: cover


@media screen and (min-width: 6588px)
  .partnerBannerWrapper
    &:before
      top: -70rem
      width: 190rem
      height: 190rem
      background-size: cover


@media screen and (max-width: 1280px)
  .partnerBanner
    flex-wrap: wrap

    .left, .right
      max-width: 100%
      width: 100%

      button
        max-width: 50%

    .right
      top: -4rem

  .partnerBannerWrapper
    &:before
      top: 20rem

@media screen and (max-width: 768px)
  .partnerBannerWrapper
    &:before
      top: 30rem
      object-fit: cover

@media screen and (max-width: 576px)
  .partnerBannerWrapper
    &:before
      top: 25rem
      right: -45rem



