@import '../../../theme'

.homeBanner

  h1
    text-align: center
    margin-bottom: 2rem
    padding: 0 6rem

  p
    color: $span-color
    margin-bottom: 1rem


@media screen and (max-width: 768px)
  .homeBanner
    h1
      padding: 0