@import '../../theme'

.footer
  background: $main-color
  width: 100vw
  padding: 4rem 0 1rem 0

  label
    color: $lite-span-color

  h2, h1
    color: $white-color

  p, a span
    color: $lite-span-color
    line-height: 1.2rem

  a span, a
    font-family: InterBold, sans-serif
    font-size: .8rem
    line-height: 1.2rem

  .header
    display: flex
    align-items: flex-start
    justify-content: space-between
    padding-bottom: 2rem
    border-bottom: $card-border-style_dark

  .header__text
    max-width: 20rem

.footer__block, .footer__copiright
  display: flex
  align-items: flex-start
  justify-content: space-between

  p span, h3
    color: $lite-span-color

  a
    color: $white-color

.current
  color: $brand-color !important

.footer__copiright
  margin-top: 2rem

.footer__right
  display: flex
  gap: 1rem

  a
    font-size: .8rem

.footer__social, .footer__menu
  p
    margin-bottom: 1rem

.footer__menu_links
  display: flex
  flex-direction: column

  a
    margin-bottom: .5rem
    font-size: .8rem
    text-decoration: none
    transition: color .2s ease-in-out

.footer__contacts
  display: flex
  flex-direction: column
  align-items: self-end
  max-width: 16rem
  text-align: right

  h3
    margin-bottom: 1rem

  p
    margin-bottom: 1rem
    color: $white-color

    span
      color: $lite-span-color

.footer__social_icons
  display: flex
  gap: 1rem


@media (hover: hover)
  .footer__menu_links
    a:hover
      color: $brand-color


@media screen and (max-width: 1280px)
  .footer__block
    flex-wrap: wrap
  .footer__Logo, .footer__menu, .footer__social, .footer__contacts
    width: 50%
    margin-bottom: 2rem

  .footer__menu
    justify-content: flex-end
    text-align: right

@media screen and (max-width: 768px)
  .footer__Logo, .footer__menu, .footer__social, .footer__contacts
    width: 100%
    text-align: left
    justify-content: flex-start
    align-items: flex-start

  .footer__copiright
    flex-direction: column
    margin-top: 0
    padding-bottom: 1rem


  .footer__copiright_text
    margin-bottom: 1rem

  .footer__right, .footer .header
    flex-direction: column

  .footer .header
    h3
      margin-bottom: 1rem
