@import '../../theme'

.columns
  display: flex
  align-items: flex-start
  gap: 1.2rem

.column__left
  width: 32.49%

.column__right
  width: 75.92%

.columnsSmall
  padding: 0 12rem
  h1
    text-align: center

  .image
    width: 1400px
    max-width: 100%
    border-radius: 1rem
    height: auto

  p
    margin: 1.2rem 0
    line-height: 1.5rem
    font-size: 1rem

  h2, h3, h4
    margin-bottom: 1.2rem

  ul, ol
    margin: 1rem 0 1rem 3rem

    li
      line-height: 1.4rem
      margin-bottom: 1rem

  a
    color: $brand-color
    text-decoration: underline
    transition: color .2s ease-in-out
    font-family: InterSemiBold, sans-serif

  strong
    font-family: InterBold, sans-serif

  blockquote
    margin-left: 2rem
    padding-left: 2rem
    margin-right: 2rem
    padding-right: 2rem
    border-left: .2rem solid $brand-color
    color: $span-color

@media (hover: hover)
  .columnsSmall
    a:hover
      color: $span-color

@media screen and (max-width: 1920px)
  .column__left
    width: 31.99999%

  .column__right
    width: 65.92%


@media screen and (max-width: 1280px)
  .columns
    flex-direction: column

  .column__left, .column__right
    width: 100%

  .columnsSmall
    padding-right: 0

@media screen and (max-width: 768px)
  .columnsSmall
    padding-left: 0

  .columnsSmall
    blockquote
      margin-left: 0
      margin-right: 0
      padding-right: 0



