@import '../../theme'

.container
  margin-left: auto
  margin-right: auto
  width: calc(1800px + 1.2rem)
  padding: 0 1.2rem

@media screen and (max-width: 1920px)
  .container
    width: 1159px

@media screen and (max-width: 1280px)
  .container
    width: 728px

@media screen and (max-width: 768px)
  .container
    width: 430px

@media screen and (max-width: 576px)
  .container
    width: 100%