@import '../../theme'

.fixed
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 1000

  &.bg
    background-color: $white-color

  &.shadow
    box-shadow: 0 .1rem 1rem $box-shadow-color-rgba

.menuContent
  display: flex
  align-items: center
  justify-content: space-between


.item
  display: flex
  justify-content: space-between
  align-items: flex-start
  gap: 1rem