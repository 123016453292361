.share
  display: flex
  align-items: center
  gap: 1rem

  a
    display: flex
    width: 3rem
    height: 3rem

    img
      max-width: 100%