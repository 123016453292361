@import '../../theme'

.contacts
  display: flex
  flex-direction: column

  img
    width: auto
    max-width: inherit

.item
  display: flex
  align-items: center
  gap: 1rem
  text-decoration: none
  font-size: 1rem
  margin-bottom: 1rem

  &.right
    justify-content: flex-end

  span
    transition: color .2s ease-in-out
    font-family: "InterSemiBold", sans-serif


@media (hover: hover)
  .item:hover span
    color: $brand-color