@import '../../theme'

.translater
  p
    font-size: 1rem
    font-family: InterSemiBold, sans-serif
    text-transform: uppercase
    cursor: pointer
    transition: color .2s ease-in-out

  .currentLang
    display: flex
    gap: .5rem

    img
      transition: all .2s ease-in-out

    &.active img
      transform: rotate(-180deg)

  .languages
    position: absolute
    right: 0
    padding: 1.2rem 1.6rem
    border: $card-border-style
    background-color: $card-fill-color
    border-radius: $border-radius
    margin-top: 1rem
    display: none

    p
      margin-bottom: 1rem

      &:last-child
        margin-bottom: 0

    &.active
      display: block


@media (hover: hover)
  .translater
    p:hover
      color: $brand-color
    &.mobile
      p:hover
        color: $brand-color

@media screen and (max-width: 1280px)
  .translater
    display: none

    &.mobile
      display: block
      z-index: 1001


