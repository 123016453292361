@import "../../theme"

.partnerBenefit
  padding: 0

.partnerBenefit__title
  display: flex
  align-items: flex-start
  justify-content: space-between

  h2
    padding-right: 22rem
  p
    max-width: 20rem

.partnerBenefit__content
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  justify-content: center
  gap: 1rem

  margin-top: 4rem


@media screen and (max-width: 1280px)
  .partnerBenefit__title
    h2
      padding-right: 3rem


@media screen and (max-width: 768px)
  .partnerBenefit__title
    flex-wrap: wrap

    h2
      margin-bottom: 2rem
      padding-right: 0