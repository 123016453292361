@import '../../theme'

.cardsBlock
  display: flex
  flex-wrap: wrap
  gap: 1.2rem
  width: 100%

.card, .small .img, .dark
  background: $card-fill-color
  border: $card-border-style
  border-radius: $border-radius
  padding: $card-padding
  width: 24.08%
  display: flex
  flex-direction: column
  align-items: flex-start
  cursor: pointer !important

  &.news
    width: 32.49%
    justify-content: flex-end
    padding: calc($card-padding - .5rem)

    img
      max-height: 10rem
      object-fit: contain
      margin: auto
      display: block

    h4, h3
      flex: auto
      margin-bottom: 1rem
      text-transform: none

    img
      width: 1000px
      max-width: 100%
      border-radius: $border-radius

  .img
    width: 100%
    height: auto

.dark
  width: 32.222%

.default
  padding: calc($card-padding - .5rem)
  h3
    margin-bottom: .8rem

  img
    max-height: 10rem
    object-fit: contain
    margin: auto
    display: block

.partners
  align-items: center
  justify-content: center
  width: 32.11%
  height: 10rem

  img
    object-fit: contain
    margin: auto
    display: block


.price, .info
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%


.info
  padding: .6rem 0
  border-bottom: $card-border-style

  p
    width: 50%

.price
  margin-top: 2rem

  span
    font-family: InterBold, sans-serif

img
  max-width: 100%
  height: auto
  object-fit: contain

.small .img
  width: 100%
  height: 100%

.small
  text-decoration: none
  cursor: pointer
  h3
    text-align: center
    margin-top: 2rem

  img
    height: 100%


.dark
  background: $main-color
  height: 20rem
  display: flex
  flex-direction: column
  justify-content: space-between

  .counter
    display: flex
    width: 6rem
    height: 3rem
    border-radius: 2rem
    padding: 0 1rem
    align-items: center
    justify-content: space-between
    background: $dark-color
    margin-bottom: 1rem

    p
      color: $white-color

  h3
    color: $white-color
    margin-bottom: 1rem

  p
    color: $lite-span-color

@media screen and (max-width: 1920px)
  .card, .card.news
    width: 31.99999%

  .partners
    width: 31.37%


@media screen and (max-width: 1280px)
  .card, .card.news
    width: 48.4%

    .img
      height: 192px

  .partners
    width: 29.25%

  .dark
    width: 48.55%

  .dark:first-child
    display: none

@media screen and (max-width: 768px)
  .card, .card.news, .partners
    width: 100%

    .price
      flex-direction: column

      p
        margin-bottom: 2rem

  .dark
    width: 100%