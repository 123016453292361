@import '../../theme'

.slider
  margin-left: -12.5rem
  margin-right: -12.5rem
  padding: 2rem 0
  background: $white-color
  margin-top: 1rem

  img
    height: 20rem
    width: 20rem
    object-fit: contain
    background: $card-fill-color
    border-radius: 1rem
    padding: 2rem
    border: $card-border-style

.title strong
  font-family: InterSemiBold, sans-serif

.prices
  display: flex
  gap: 1rem
  align-items: center
  justify-content: center

  p, span
    font-family: InterSemiBold, sans-serif
    font-size: 1.2rem

    span
      color: $brand-color

.list
  margin-top: 1rem
  button
    max-width: 50%
    margin: 4rem auto 2rem
    img
      max-width: 1.5rem
      object-fit: contain

  h4
    margin-bottom: 0

.cards
  display: flex
  gap: 1rem

@media screen and (max-width: 1280px)
  .slider
    margin-right: 0

  .prices
    flex-wrap: wrap

    p
      margin-bottom: 0

@media screen and (max-width: 768px)
  .slider
    margin-left: 0

  .cards
    flex-wrap: wrap
    display: contents

    h3
      margin-top: 1rem
      margin-bottom: 2rem

