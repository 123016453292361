@import '../../theme'

.back
  position: sticky
  margin-left: -12rem
  top: 10rem
  a.a_link
    display: flex
    align-items: center
    gap: 1rem
    text-decoration: none !important
    transition: color .2s ease-in-out
    color: $main-color !important
    max-width: 240px


  img
    width: 1.5rem
    height: 1.5rem
    transform: rotate(90deg)


@media (hover: hover)
  .back
    a.a_link:hover
      color: $brand-color !important

@media screen and (max-width: 768px)
  .back
    position: relative
    margin-left: 0
    top: 0
    margin-bottom: 2rem

    a
      justify-content: center

@media screen and (max-width: 501px)
  .back a.a_link
    max-width: 100% !important
    width: 100% !important