.popup
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 10000
  background-color: rgba(0,0,0,.5)
  display: none

  .content_popup
    width: 1024px
    height: 768px
    max-width: 100%
    padding: 3rem
    background-color: #FFFFFF
    max-height: fit-content
    display: flex
    align-items: center
    justify-content: center
    border-radius: 2rem

    .img
      width: 1024px
      height: 768px
      max-width: 100%
      max-height: fit-content
      object-fit: contain

  &.popup_active
    display: flex
    align-items: center
    justify-content: center



@media screen and (max-width: 1280px)
  .popup .content_popup, .popup .content_popup .img
    height: 560px

@media screen and (max-width: 768px)
  .popup .content_popup, .popup .content_popup .img
    height: 480px

@media screen and (max-width: 576px)
  .popup .content_popup, .popup .content_popup .img
    height: 320px