@import '../../theme'

.list
  display: flex
  justify-content: space-between
  flex-direction: column
  margin-bottom: 2rem

  .item
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: $card-border-style