@import '../../theme'

.input
  display: flex
  flex-direction: column
  margin-bottom: 1rem

  label
    color: $lite-span-color
    margin-bottom: .5rem

  input
    color: $white-color
    outline: none
    background: transparent
    padding: .5rem 0
    border-top: none
    border-left: none
    border-right: none
    border-bottom: $card-border-style_dark
    font-size: .8rem
    letter-spacing: .05rem