@import "./theme"

@font-face
  font-weight: 100
  font-family: InterExtraLite
  src: url("../public/fonts/Inter-ExtraLight.ttf")
  font-display: fallback

@font-face
  font-weight: 200
  font-family: InterLite
  src: url("../public/fonts/Inter-Light.ttf")
  font-display: fallback

@font-face
  font-weight: 300
  font-family: InterThin
  src: url("../public/fonts/Inter-Thin.ttf")
  font-display: fallback

@font-face
  font-weight: 400
  font-family: InterRegular
  src: url("../public/fonts/Inter-Regular.ttf")
  font-display: fallback

@font-face
  font-weight: 500
  font-family: InterMedium
  src: url("../public/fonts/Inter-Medium.ttf")
  font-display: fallback

@font-face
  font-weight: 600
  font-family: InterBold
  src: url("../public/fonts/Inter-Bold.ttf")
  font-display: fallback

@font-face
  font-weight: 700
  font-family: InterSemiBold
  src: url("../public/fonts/Inter-SemiBold.ttf")
  font-display: fallback

@font-face
  font-weight: 800
  font-family: InterExtraBold
  src: url("../public/fonts/Inter-ExtraBold.ttf")
  font-display: fallback

@font-face
  font-weight: 900
  font-family: InterBlack
  src: url("../public/fonts/Inter-Black.ttf")
  font-display: fallback

*
  padding: 0
  margin: 0
  position: relative
  font-size: 18px
  font-weight: normal
  box-sizing: border-box
  font-family: "InterRegular", sans-serif
  color: $main-color


.iframe
  margin-top: 2rem
  width: 100%
  border-radius: 1.2rem
  height: 450px

.marker
  width: 2rem
  height: 2rem
  border-radius: 1rem
  background-color: $brand-color

.marker-label
  background: $card-fill-color
  padding: .3rem
  margin-top: 3rem
  border: 1px solid $brand-color
  border-radius: .4rem
  font-size: .8rem !important
  font-weight: 500

h1, h2, h3, h4
  font-family: InterBold, sans-serif

h1, h2
  letter-spacing: -3%
  padding-bottom: .6rem
  line-height: 130%

h1
  font-size: 3.2rem
  line-height: 110%

  span
    font-size: 3.2rem
    line-height: 110%
    color: $brand-color
    font-family: InterBold, sans-serif

h2
  font-size: 2.5rem
  line-height: 110%

  span
    margin-top: -.5rem
    position: absolute
    padding-left: 1rem
    font-size: 1.8rem
    color: $span-color
    font-family: InterSemiBold, sans-serif

h3
  font-size: 1.2rem
  text-transform: uppercase
  line-height: 130%

h4
  font-size: 1.2rem
  padding-bottom: .6rem
  line-height: 130%

h5
  font-size: 1rem
  padding-bottom: .6rem
  line-height: 130%

.menuText
  text-align: right
  color: $span-color
  font-size: 1rem

.slick-slide
  padding: 0 .5rem

.slick-dots
  margin-left: 0 !important
  bottom: -2rem

hr
  border-top: $card-border-style

img
  height: auto

@media screen and (max-width: 1920px)
  h1
    font-size: 2.2rem

    span
      font-size: 2.2rem

  p
    font-size: .9rem
    line-height: 1.2rem

@media screen and (max-width: 1280px)
  h1
    font-size: 2rem

    span
      font-size: 2rem

  h2
    font-size: 1.8rem

  h4
    font-size: 1rem

@media screen and (max-width: 768px)
  .slick-slide
    padding: 0


