@import "../../theme"

.accordion
  width: 100%
  transition: all .4s ease-in-out

.accordionItem
  width: 100%

  .accordionItemHeader
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1rem 0
    cursor: pointer
    font-family: "InterBold", sans-serif
    transition: color .2s ease-in-out

    img
      transition: all .2s ease-in-out

    &.active
      color: $brand-color
      img
        transform: rotate(-180deg)


  .accordionItemBody
    height: 0
    max-height: 100%
    overflow: hidden
    border-bottom: $card-border-style
    transition: height .2s ease-in-out

  &.banner
    .accordionItemHeader
      justify-content: center
    button
      margin: auto
    .accordionItemBody
      border: none
      padding: 0 10rem

    h2, h3, h4
      padding: 1rem 0

    p
      font-size: .9rem

      &:first-child
        padding-top: 2rem

    h2, h3, h4, p
      text-align: center

  .accordionItemContent
    font-size: .8rem
    padding-bottom: 1rem
    line-height: 1.2rem
    color: $dark-stroke-color


@media screen and (max-width: 1280px)
  .accordionItemBody
    padding: 0 !important
