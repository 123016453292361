@import "../../theme"


.menu
  display: flex
  align-items: center
  gap: 1rem

  &.mobile
    display: none
    cursor: pointer
    transition: opacity .2s ease-in-out
    .menuHeader
      display: flex
      align-items: center
      gap: 1rem

      p
        font-family: InterSemiBold, sans-serif
        font-size: 1rem
        transition: color .2s ease-in-out

    .menuItems
      display: none

      &.active
        display: flex
        justify-content: flex-end
        align-items: flex-start
        position: fixed
        top: 0
        right: 0
        bottom: 0
        left: 0
        z-index: 1000
        width: 100vw
        height: 100vh
        backdrop-filter: blur(4px)

        .menuClose
          background-color: $main-color-rgba
          width: calc(100vw - 18rem)
          height: 100vh
          top: 0
          right: 0
          bottom: 0
          left: 0
          position: fixed


        .menuItem
          background-color: $white-color
          height: 100vh
          padding: 1rem 2rem
          width: 18rem
          display: flex
          flex-direction: column
          justify-content: flex-start
          overflow: auto

          a
            text-align: right

  a
    text-decoration: none
    transition: color .2s ease-in-out
    font-family: InterSemiBold, sans-serif

    &.menuItemLink
      margin-bottom: 1rem

  .menuItemClose
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 2rem

.current
  color: $brand-color !important

@media (hover: hover)
  .menu
    a:hover
      color: $brand-color

  .mobile:hover
    p.title
      color: $brand-color

@media screen and (max-width: 1280px)
  .desktop
    display: none

  .menu.mobile
    opacity: 1
    display: flex