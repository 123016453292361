@import '../../theme'

.error
  display: flex
  justify-content: center
  width: 100%

  p, h1
    text-align: center

  .button
    max-width: 50%

  .error_number, strong
    font-size: 10rem
    font-family: InterBold, sans-serif

    strong
      color: $brand-color