@import '../../theme'

.button, button, .full
  height: 3rem
  border: $card-border-style
  display: flex
  align-items: center
  justify-content: center
  border-radius: 2rem
  width: 6rem
  text-decoration: none
  font-family: "InterMedium", sans-serif
  transition: background-color .2s ease-in-out
  background-color: $white-color
  cursor: pointer

  &.withoutIcon
    background-color: transparent
    color: $brand-color
    border: $card-border-style_dark

  &.borderButton
    border-left: none
    border-top: none
    border-right: none
    border-radius: 0
    background-color: transparent
    color: $brand-color
    width: 100%
    border-bottom: $card-border-style_dark
    transition: color .2s ease-in-out

  &.icon
    gap: 1rem
    width: 100%
    max-width: 14rem

.full
  margin-top: 2rem
  color: $white-color !important
  text-decoration: none !important
  transition: all .2s ease-in-out !important
  text-align: center !important
  justify-content: center !important

  img
    width: 1.8rem
    height: 1.8rem
    max-width: 1.8rem !important
    max-height: 1.8rem !important

    margin-bottom: 0 !important

button, .full
  background: $brand-color
  border: none
  width: 100%
  gap: 1rem

@media (hover: hover)
  .button:hover, button:hover, .full:hover
    background-color: $card-fill-color

  .full:hover
    color: $dark-stroke-color !important

  button
    &.borderButton:hover
      background-color: transparent
      color: $lite-span-color


button
  &:disabled
    cursor: default
    background-color: $span-color

@media screen and (max-width: 768px)
  .button, button, .full
    width: 100%
    max-width: 100%
    min-width: 100%