$main-color: #1F1E24
$dark-color: #0F0F10
$main-color-rgba: rgba(31,30,36,.7)
$main-color-rgba2: rgba(31,30,36,.95)
$box-shadow-color-rgba: rgba(31,30,36,.3)
$brand-color: #FF7D39
$brand2-color: #C24D10
$span-color: #706E81
$lite-span-color: #A5A5A7
$dark-stroke-color: #38383D
$stroke-color: #D6D6D6
$card-fill-color: #FBF9F8
$white-color: #FFFFFF

$card-border-style: 1px solid $stroke-color
$card-border-style_dark: 1px solid $dark-stroke-color

$border-radius: 1.2rem

$card-padding: 1.8rem